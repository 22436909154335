module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"gatsby-remark-autolink","maintainCase":true,"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V5JQ7XNJBY"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Seelay","short_name":"Seelay","start_url":"/","description":"Product designer based in Chandigarh, India with experience in delivering end-to-end UX/UI design for digital products.","background_color":"#FFFFFF","theme_color":"#FF2222","display":"standalone","icon":"src/static/s-logo-red-maskable.svg","lang":"en","orientation":"portrait-primary","related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=www.seelay.in","id":"www.seelay.in"}],"prefer_related_applications":true,"screenshots":[{"src":"/screenshots/darkmode.png","sizes":"1280x720","type":"image/png","platform":"narrow","label":"Dark Mode"},{"src":"/screenshots/lightmode.png","sizes":"1280x720","type":"image/png","platform":"narrow","label":"Light Mode"}],"categories":["portfolio","design","development","art","opensource"],"shortcuts":[{"name":"Open Blog","short_name":"Blog","description":"Read some blogs.","url":"/blog","icons":[{"src":"maskable/icon-blog.png","sizes":"192x192"}]}],"url_handlers":[{"origin":"https://www.seelay.in"},{"origin":"https://seelay.in"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8030257915c7b3a652c21a80db4dbc3b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff2222","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-V5JQ7XNJBY","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
